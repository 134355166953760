<template>
<div class="poem-holder">
    <div class="top-poem-nav">
        <svg class="icon-magnify" @click="fontSizeUp()">
          <path fill="currentColor" d="M5.12,14L7.5,7.67L9.87,14M6.5,5L1,19H3.25L4.37,16H10.62L11.75,19H14L8.5,5H6.5M18,7L13,12.07L14.41,13.5L17,10.9V17H19V10.9L21.59,13.5L23,12.07L18,7Z" />
        </svg>
        <svg class="icon-magnify" @click="fontSizeDown()">
          <path fill="currentColor" d="M5.12,14L7.5,7.67L9.87,14M6.5,5L1,19H3.25L4.37,16H10.62L11.75,19H14L8.5,5H6.5M18,17L23,11.93L21.59,10.5L19,13.1V7H17V13.1L14.41,10.5L13,11.93L18,17Z" />
        </svg>
      <router-link
        class="close-poem-btn"
        :to="{ name: 'PoemsBox', params: { theme: theme } }"
      ><p>&#10006;</p></router-link>
    </div>
    <div class="all-wrapper">
      <div class="img-wrapper" ref="imgwrapper">
        <img
          v-if="poemImg != ''"
          :src="'/img/poems/' + poemImg"
          :alt="poemAlt"
        />
      </div>
      <div class="poem-wrapper" ref="wrapper">
        <p
          class="title-display"
          :style="{fontSize: titleFontSize + 'rem'}"
        >{{ poem.title }}</p>
        <div class="text-display-box">
          <p
            v-html="poemText"
            class="text-display"
            id="text-display"
            :style="{fontSize: textFontSize + 'rem'}"
          ></p>
        </div>
      </div>
    </div>
    <div class="poems-nav">
      <transition name="fade">
        <router-link
          class="btn-left"
          v-if="prevPoem != null"
          :to="{ name: 'PoemPage', params: { poemLink: prevPoem.poemLink } }"
        >
          <p class="nav-btn">poprzedni</p>
          <p>{{ prevPoem.title }}</p>
        </router-link>
      </transition>
      <transition name="fade">
        <router-link
          class="btn-right"
          v-if="nextPoem != null"
          :to="{ name: 'PoemPage', params: { poemLink: nextPoem.poemLink } }"
        >
          <p class="nav-btn">następny</p>
          <p>{{ nextPoem.title }}</p>
        </router-link>
      </transition>
    </div>
  </div>
</template>

<script>

export default {
  name: 'PoemPage',
  data: () => ({
    poem: null,
    poemIndex: null,
    prevPoem: null,
    prevPoemIndex: null,
    nextPoem: null,
    nextPoemIndex: null,
    titleFontSize: 2.5,
    textFontSize: 1.6,
  }),
  // head: {
  //   meta: function () {
  //     return [
  //       { property: 'og:url', content: 'http://www.robertiwanowski.pl' + this.$route.path },
  //       { property: 'og:title', content: this.poem.title },
  //       { property: 'og:description', content: this.poem.text },
  //       { property: 'og:image', content: 'http://static01.nyt.com/images/2015/02/19/arts/international/19iht-btnumbers19A/19iht-btnumbers19A-facebookJumbo-v2.jpg' }
  //     ]
  //   },
  // },
  computed: {
    theme () {
      return this.$route.params.theme
    },
    newestOptions () {
      return this.$store.state.data.poems.newest
    },
    poems () {
      return this.$store.state.poems.filter(el => {
        return this.theme === this.newestOptions.link ? el.newest === true : el.link === this.theme
      })
    },
    poemText () {
      if (this.poem != null) {
        return this.poem.text.replace(/\n/g, '<br>') + '<span style="display: block; font-size: 1.2rem; font-style: italic; margin-top: 25px; text-align: right;">Robert Iwanowski, ' + this.poem.date + '</span>'
      }
      else {
        return ''
      }
    },
    facebookLink () {
      return 'https://www.facebook.com/sharer/sharer.php?u=' + 'http://www.robertiwanowski.pl' + this.$route.path
    },
    poemImg () {
      if (this.poem != null && this.poem.src) {
        return this.poem.src
      }
      else {
        return ''
      }
    },
    poemAlt () {
      if (this.poem != null && this.poem.alt) {
        return this.poem.alt
      }
      else {
        return ''
      }
    }
  },
  methods: {
    setPoem (poem, index) {
      this.poem = poem
      this.poemIndex = index
      if (poem != null && index > 0) {
        this.prevPoem = this.poems[index - 1]
        this.prevPoemIndex = index - 1
      }
      else {
        this.prevPoem = null
        this.prevPoemIndex = null
      }
      if (poem != null && index < this.poems.length - 1) {
        this.nextPoem = this.poems[index + 1]
        this.nextPoemIndex = index + 1
      }
      else {
        this.nextPoem = null
        this.nextPoemIndex = null
      }
      document.body.style.overflowY = 'hidden'
    },
    showPoem () {
      this.$refs.wrapper.style.opacity = 1
      this.$refs.wrapper.style.transform = 'translateX(0)'
      this.$refs.imgwrapper.style.opacity = 1
      this.$refs.imgwrapper.style.transform = 'translateX(0)'
    },
    hidePoem () {
      this.$refs.wrapper.style.opacity = 0
      this.$refs.wrapper.style.transform = 'translateX(50px)'
      this.$refs.imgwrapper.style.opacity = 0
      this.$refs.imgwrapper.style.transform = 'translateX(-50px)'
    },
    fontSizeUp () {
      this.titleFontSize = this.titleFontSize + 0.2
      this.textFontSize = this.textFontSize + 0.2
    },
    fontSizeDown () {
      this.titleFontSize = this.titleFontSize - 0.2
      this.textFontSize = this.textFontSize - 0.2
    }
  },
  created () {
    const actualPoem = this.poems.filter(el => {
      return el.poemLink == this.$route.params.poemLink
    })[0]
    const index = this.poems.findIndex(el => {
      return el == actualPoem
    })
    this.setPoem(actualPoem, index)
  },
  mounted () {
    setTimeout(() => {
      this.showPoem()
    }, 500)
  },
  beforeRouteUpdate (to, from, next) {
    this.hidePoem()
    setTimeout(() => {
      next()
    }, 500);
  }
}
</script>

<style scoped lang="scss">

.poem-holder {
  width: 100%;
  height: 100%;
}
.titles-container {
  padding: 0 30px;
  @media screen and (min-width: 960px) {
    transform: translateY(-40px);
  }
}
.title {
  font-weight: 300;
  margin: 10px 15px;
  padding: 0 10px;
  transition: all .15s linear;
  border-left: 0px solid #EB7054;
  @media screen and (min-width: 600px) {
    width: 300px;
    &:hover {
      color: #EB7054;
      cursor: pointer;
      border-left: 10px solid #EB7054;
    }
  }
}
.poem-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  max-width: 800px;
  max-height: 1300px;
  width: 90vw;
  height: 90vh;
  background-color: white;
  box-shadow: 0px 5px 15px 5px #444;
  z-index: 300;
  overflow-y: scroll;
  overflow-x: hidden;
}
.poem-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 30px;
  transition: all .25s ease-in-out;
  flex-grow: 1;
  transform: translateX(50px);
  opacity: 0;
}
.poem-bg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,.5);
    z-index: 100;
}
.top-poem-nav {
    position: sticky;
    top: 5px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    z-index: 500;
}
.icon-magnify {
  width: 40px;
  height: 50px;
  color: #444;
  transition: all .25s linear;
  @media screen and (min-width: 1024px) {
      &:hover {
          cursor: pointer;
          color: #EB7054;
      }
    }
}
.close-poem-btn.router-link-active {
    width: 40px;
    height: 70px;
    font-size: 3rem;
    color: #444 !important;
    background-color: transparent;
    transition: all .25s linear;
    margin-left: 15px;
    @media screen and (min-width: 1024px) {
      &:hover {
          cursor: pointer;
          color: #EB7054 !important;
      }
    }
}
.title-display {
  font-weight: 300;
  font-size: 2.5rem;
  margin: 0 15px 20px 15px;
  text-align: center;
  transition: all .15s linear;
}
.text-display-box {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.text-display {
  margin: 0 15px 30px 15px;
  display: inline-block;
  line-height: 2.5rem;
  transition: all .15s linear;
}
.poems-nav {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  margin-top: 15px;
  .btn-left, .btn-right {
    text-align: center;
    margin-bottom: 30px;
  }
  @media screen and (min-width: 600px) {
    flex-direction: row;
    a.btn-left, a.btn-right {
      flex-basis: 50%;
      padding: 10px 15px 20px 15px;
      transition: all .25s linear;
      height: 100%;
      max-height: 80px;
      &:hover {
        background-color: #EB7054;
        color: white;
        cursor: pointer;
      }
    }
    .btn-left {
      text-align: left;
      margin-left: 25px;
      margin: 15px auto 0 0;
      padding-left: 30px;
    }
    .btn-right {
      text-align: right;
      margin-right: 25px;
      margin: 15px 0 0 auto;
      padding-right: 30px;
    }
  }
}
.nav-btn {
  font-style: italic;
  font-size: 1rem;
}
.error {
  color: red;
  font-size: 2rem;
  font-weight: 700;
  text-align: center;
  margin: 15px;
}

.all-wrapper {
  display: flex;
  flex-direction: row;
  @media screen and (max-width: 900px) {
    flex-direction: column-reverse;
    .img-wrapper {
      margin: 0 auto;
      max-width: 80%;
    }
  }
}
.img-wrapper {
  margin: 0 25px;
  transition: all .25s ease-in-out;
  transform: translateX(-50px);
  opacity: 0;
  max-width: 50%;
  img {
    width: 100%;
  }
}
</style>
